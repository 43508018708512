export default function getTodayDate(): string {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = today.getFullYear();
  const todayStr = `${yyyy}-${mm}-${dd}`;
  return todayStr;
}

export const getMinorMaxDate = (
  radius: number,
  operation: 'min' | 'max',
): string => {
  const [year, month, day] = getTodayDate().split('-');
  const newYear =
    operation === 'min' ? Number(year) - radius : Number(year) + radius;
  return `${newYear}-${month}-${day}`;
};

export const formatDate = (date: string, type?: 'invert'): string => {
  if (type === 'invert') {
    // Convert from yyyy-mm-dd to dd/mm/yyyy
    const [yyyy, mm, dd] = date.split('-');
    if (!yyyy || !mm || !dd) {
      return 'Invalid Date';
    }
    return `${dd}/${mm}/${yyyy}`;
  }
  // Convert from dd/mm/yyyy to yyyy-mm-dd
  const [dd, mm, yyyy] = date.split('/');
  if (!dd || !mm || !yyyy) {
    return 'Invalid Date';
  }
  return `${yyyy}-${mm}-${dd}`;
};
