import styled from 'styled-components';
import { colors } from '~/styles/settings/colors';

import { breakpoints } from '~/styles/tools/breakpoints';

export const VideoWrapper = styled.article`
  padding: 16px;
  transition: background 0.3s;
  position: relative;
  width: 100%;

  &:hover {
    cursor: pointer;
    background: #fff0dc 0% 0% no-repeat padding-box;
  }

  @media (max-width: ${breakpoints.small}) {
    padding: 0;
    max-width: 300px;

    &:hover,
    &:focus {
      background: none;
    }
  }
`;

export const VideosContent = styled.section`
  display: block;
`;

export const MediaContainer = styled.div`
  width: 100%;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: white;

  @media (max-width: ${breakpoints.small}) {
    height: 200px;
  }
`;

export const VideosImage = styled.img`
  width: auto;
  height: 100%;
`;

export const VideosField = styled.video`
  width: auto;
  height: 100%;
`;

// export const VideosField = styled.video`
//   width: 300px;
//   height: 250px;

//   @media (max-width: ${breakpoints.small}) {
//     height: 320px;
//   }
// `;

export const VideosInfo = styled.section`
  @media (max-width: 768.9px) {
    max-width: 310px;
  }
`;

export const VideosTitle = styled.h3`
  color: ${colors.lightGrey};
  font-size: 20px;
  font-weight: bold;
`;

export const VideosDescription = styled.p`
  color: ${colors.lightGrey};
  font-size: 16px;
  max-width: 393px;
`;
