import { InputHTMLAttributes } from 'react';

import { SubmitHandler as SubmitHandlerBase } from '@unform/core';
import { NavLinkProps } from 'react-router-dom';
import { maskArray } from 'react-text-mask';

import {
  Professional,
  UserBase,
  UserProfessional,
  UserCredentials,
  UserCommon,
  UserStudent,
  ProfessionalBase,
  ProfessionalComplement,
} from './User';

export interface DatabaseFields {
  created_at: string;
  update_at: string;
  id: number;
}

export interface Professions {
  id: number;
  occupation: string;
}

export interface UserId {
  user_id: number;
}

export interface Categories {
  id: number;
  description: string;
  profession_id: number;
}

export interface Professionscategories {
  professionscategories: Categories[];
}

export interface PhotoFields {
  foto_key: string | null;
  foto_path: string | null;
  foto: string | null;
}

export interface MessageServer {
  message: string;
}

export interface AuthenticationData {
  type: 'bearer';
  token: string;
  refresh: string;
  link: string;
}

export interface ForgotPasswordFormData {
  email: string;
}

export interface PairForgetCode extends ForgotPasswordFormData {
  code: string;
}

export interface ResetPassword {
  password: string;
  id: number;
}

export interface ProfessionalForm
  extends Omit<Professional, 'amount_receivable' | 'original_price'> {
  amount_receivable: string;
  original_price: string;
}

export interface RequiredForm
  extends UserBase,
    Partial<{ professional: ProfessionalForm }> {}

export interface RequiredFormAmountNumber
  extends UserBase,
    Partial<{ professional: Partial<Professional> }> {}

export type RegisterRequest = Pick<UserCredentials, 'password'> &
  UserCommon &
  (
    | {
        role: 'professional';
        cref: string;
        professionId: number;
      }
    | {
        role: 'student';
      }
  );

export type RegisterProfessionalRequest = RegisterProfessionalRequestForm &
  PageCustomizeFormData;

export type RegisterProfessionalRequestForm = RegisterRequest & {
  confirm: string;
  date_of_birth?: string;
};

export interface PageCustomizeFormData {
  public_name: string;
  nick_name: string;
}

export interface PageCustomizeRequest
  extends Omit<PageCustomizeFormData, 'nick_name'> {
  logo?: File;
}

export interface Logo {
  name: string;
  file: File;
}

export interface PageOptionsRequest {
  page: number;
  perpage?: number;
}

export interface PageOptionsResponse<T> {
  data: T[];
  lastPage: number;
  perPage: number;
  page: number;
  total: number;
}

export enum ButtonBlackColor {
  SUCESS,
  ERROR,
  NORMAL,
}

export interface ButtonBlackStyle {
  stateUpload: ButtonBlackColor;
  isLabelFile: boolean;
}

export interface VideoError {
  isVideoErr: boolean;
  isCoverErr: boolean;
}

export type MaskType = maskArray | ((value: string) => maskArray);

export interface CardInfo {
  cardNumber: string;
  holderName: string;
  securityCode: string;
  expirationMonth: string;
  expirationYear: number;
}

export type ProfessionalRequiredForm = Omit<UserBase, 'password'> &
  Partial<ProfessionalBase & ProfessionalComplement>;

export type ProfessionalRequiredInfo = Omit<UserBase, 'password'> &
  Partial<{ role: 'professional'; professional: Professional }>;

export type RequiredInfo = Omit<ProfessionalRequiredInfo, 'image'>;

export type InputPropsAccessibility = 'aria-label' | 'title';

export interface InputMaskProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, InputPropsAccessibility> {
  name: string;
  mask: string | MaskType;
}

export type UpdateStudentResponse = UserBase &
  Omit<UserStudent, 'student'> &
  DatabaseFields;

export interface UpdateProfessionalResponse extends Professional {
  user: UserBase & Omit<UserProfessional, 'professional'> & DatabaseFields;
}

export type MediaType = 'video' | 'image' | 'file';

export interface MediaProps {
  blob: string;
  file: File | null;
  type: MediaType;
}

export enum DropdownType {
  LIVE,
  COURSE,
  PRACTICE,
  VIDEO,
  WORKOUT,
  INTERACTION,
}

export interface HandleDropdownArgs {
  id: number;
  type: DropdownType;
}

export interface DropdownProps {
  dropdownOpen: boolean;
  setDropdownOpen: (newState: boolean) => void;
  dropdownSelected?: string;
  setDropdownSelected: (state: string) => void;
  setNeedUpdate?: (newState: boolean) => void;
}

export interface ModalLike {
  modalIsOpen: boolean;
  setModalIsOpen: (visibility: boolean) => void;
}

export interface SelectOptionProps {
  label: string;
  value: string | number;
}

export interface MenuMobileItem extends NavLinkProps {
  label: string;
}

export interface SubmitHandler<T> {
  (...args: Parameters<SubmitHandlerBase<T>>): Promise<void>;
}
