import { useCallback, useEffect, useState } from 'react';

import { useStripe } from '@stripe/react-stripe-js';
import axios, { AxiosError } from 'axios';
import { FaArrowLeft } from 'react-icons/fa';
import { FiLink } from 'react-icons/fi';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';

import { ImageCheckMark, ImageLogo } from '~/assets/icons';
import { useAuth } from '~/hooks/Auth';
import { useToast } from '~/hooks/Toast';
import { Courses } from '~/models/Courses';
import { Lives } from '~/models/Lives';
import { cleanRequests } from '~/services/api';
import CourseService from '~/services/CourseService';
import LivesService from '~/services/LivesService';
import ProfessorService from '~/services/ProfessorService';
import StudentService, {
  AssociateResponseError,
} from '~/services/StudentService';
import errorHandlerToToast from '~/utils/errorHandler';
import Price from '~/utils/Price';
import { useQuery } from '~/utils/query';
import { Input } from '~/components';

import {
  ButtonBack,
  ButtonLink,
  Container,
  DescountItem,
  DescountText,
  DescountTextTwo,
  IconWrapper,
  Item,
  LinkPayment,
  List,
  PaymentBox,
  PaymentInfo,
} from './styles';

interface PaymentState {
  price: number;
  link: string;
  professional_id: number;
}

interface ParamsProps {
  publicname: string;
}

interface CardParams {
  card_name: string;
  card_number: string;
  card_validity: string;
  card_cvv: number;
}

interface StatePlan {
  plan: {
    planId: number;
    planAmount: number;
    planName: string;
    numberVideos: number;
  };
}

const Payment: React.FC = () => {
  const state = useLocation().state as StatePlan;
  const isProfessional = useQuery().get('professional');
  const { push, goBack } = useHistory();
  const { user, logout } = useAuth();
  const courseParamId = useQuery().get('course');
  const [isCourseSubscription, setIsCourseSubscription] = useState(false);
  const liveParamId = useQuery().get('live');
  const [course, setCourse] = useState<Courses>();
  const [live, setLive] = useState<Lives>();
  const isPlan = useQuery().get('plan');
  // const CouponName = useQuery().get('coupon');
  const [couponName, setCouponName] = useState<string>('');
  const { addToast } = useToast();
  const [priceText, setPriceText] = useState<string>();
  const [payment, setPayment] = useState<PaymentState>({
    link: '',
    price: 0,
    professional_id: 0,
  });
  const stripe = useStripe();
  const { publicname } = useParams<ParamsProps>();

  const associatePlan = useCallback(async () => {
    if (user?.role === 'student') {
      try {
        // stripe - pagamento plano

        const response = couponName
          ? await StudentService.associatePlano({
              professionalId: payment.professional_id,
              studentId: user.student.id,
              creditCardHash: 'teste',
              coupon_name: couponName,
            })
          : await StudentService.associatePlano({
              professionalId: payment.professional_id,
              studentId: user.student.id,
              creditCardHash: 'teste',
            });

        if (response.response?.status === 409) {
          addToast({
            title: 'Aluno já associado',
            description: `Aluno associado ao plano do profesor(a): ${publicname}`,
            type: 'info',
          });
          push('/student/my-workouts');
        }

        if (response.response?.status === 400) {
          addToast({
            title: 'Cupom invalido',
            description: `Digite um cupom valido`,
            type: 'error',
          });
        }

        try {
          stripe?.redirectToCheckout({
            sessionId: response.data.sessionId,
          });
        } catch (error) {
          console.log('error:', error);
        }
      } catch (e: any) {
        if (axios.isAxiosError(e)) {
          const err = e as AxiosError<AssociateResponseError>;
          if (err.response?.status === 409) {
            addToast({
              title: 'Aluno já associado',
              description: `Aluno associado ao plano do profesor(a): ${publicname}`,
              type: 'info',
            });
            push('/student/my-workouts');
          }
          if (err.response?.status === 400) {
            addToast({
              title: 'Precisamos de mais dados',
              description: 'Complete seus dados para continuar o pagamento.',
              type: 'info',
            });
          }
        }
      }
    }
  }, [
    addToast,
    push,
    payment.professional_id,
    user,
    stripe,
    couponName,
    setCouponName,
  ]);

  const professionalSignsPlan = useCallback(async () => {
    if (user?.role === 'professional') {
      try {
        const response = await ProfessorService.professionalsPlanSign({
          planId: state?.plan.planId,
        });

        if (response?.status === 201) {
          logout();
          addToast({
            title: 'Profissional cadastrado no plano',
            description: `Realize o login para prosseguir para configurar o pagamento/recebimento`,
            type: 'info',
          });
          if (user.link) {
            window.location.href = user.link;
          }
          // push('/signin');
        }
        // ADD NO CODIGO STRIPE
        // stripe?.redirectToCheckout({
        //   sessionId: response.data.sessionId,
        // });
      } catch (e: any) {
        console.log(e);
      }
    }
  }, [addToast, push, user, stripe]);

  const associateCourse = useCallback(async () => {
    if (user?.role === 'student') {
      try {
        let courseId = 0;
        if (courseParamId && !Number.isNaN(courseParamId)) {
          courseId = parseInt(courseParamId, 10);
        }
        const response = couponName
          ? await StudentService.associateCourse({
              courseId,
              studentId: user.student.user_id,
              coupon_name: couponName,
            })
          : await StudentService.associateCourse({
              courseId,
              studentId: user.student.user_id,
            });

        if (response.response?.status === 409) {
          addToast({
            title: 'Aluno já associado',
            description: `Aluno associado ao curso`,
            type: 'info',
          });
          push('/student/my-workouts');
        }

        if (response.response?.status === 400) {
          addToast({
            title: 'Cupom invalido',
            description: `Digite um cupom valido`,
            type: 'error',
          });
        }

        // ADD NO CODIGO STRIPE
        window.location.href = response.data.url;
      } catch (e: any) {
        console.log(e);
        if (axios.isAxiosError(e)) {
          const err = e as AxiosError<AssociateResponseError>;
          if (err.response?.status === 409) {
            addToast({
              title: 'Aluno já associado',
              description: `Aluno associado ao plano do profesor(a): ${publicname}`,
              type: 'info',
            });
            push('/student/my-workouts');
          }
          if (err.response?.status === 400) {
            addToast({
              title: 'Precisamos de mais dados',
              description: 'Complete seus dados para continuar o pagamento.',
              type: 'info',
            });
          }
        }
      }
    }
  }, [addToast, courseParamId, push, user, stripe, couponName, setCouponName]);

  //  stripe - pagamento live
  const associateLive = useCallback(async () => {
    if (user?.role === 'student') {
      try {
        // stripe - pagamento live
        const response = couponName
          ? await LivesService.postAssociateLives({
              liveId: liveParamId ?? '',
              studentId: user.student.id,
              coupon_name: couponName,
            })
          : await LivesService.postAssociateLives({
              liveId: liveParamId ?? '',
              studentId: user.student.id,
            });

        if (response.status < 300) {
          setPayment({
            link: response.data.payment_url,
          } as any);
        }

        if (response.status === 400) {
          addToast({
            title: 'Cupom invalido',
            description: `Digite um cupom valido`,
            type: 'error',
          });
        }

        stripe?.redirectToCheckout({
          sessionId: response.data.sessionId,
        });
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const err = e as AxiosError<AssociateResponseError>;
          if (err.response?.status === 400) {
            addToast({
              title: 'Precisamos de mais dados',
              description: 'Complete seus dados para continuar o pagamento.',
              type: 'info',
            });
            push('/student/config', { publicname });
          }
        }
      }
    }
  }, [addToast, liveParamId, push, user, stripe]);

  const handlePlan = useCallback(async () => {
    const {
      data: { amount_receivable = 0, id: professional_id },
    } = await ProfessorService.getProfessionalByPublicname(
      publicname,
      'payment',
    );

    const priceAmountReceivable = new Price({ price: amount_receivable });
    const priceCharge = priceAmountReceivable.getChargeInFloat();

    if (amount_receivable > 0) {
      setPayment({
        price: priceCharge,
        link: '',
        professional_id,
      });
    }
  }, [publicname]);

  const handleGetCourse = useCallback(async () => {
    try {
      if (courseParamId) {
        const courseId = parseInt(courseParamId, 10);
        const [firstCourse] = await CourseService.getCourseById(courseId);
        setCourse(firstCourse);
      } else {
        throw new Error('Error no ID do curso.');
      }
    } catch (e: any) {
      const errorToToast = errorHandlerToToast(e);
      if (errorToToast) addToast(errorToToast);
    }
  }, [addToast, courseParamId, associateCourse]);

  const handleGetLive = useCallback(async () => {
    try {
      const response = await LivesService.getLives(publicname);
      setLive(
        response.data.data.filter(({ id }) => id === Number(liveParamId))[0],
      );
    } catch (error) {
      console.log(error);
    }
  }, [liveParamId, publicname]);

  const getPrice = useCallback(() => {
    const price = course?.price || payment?.price || live?.price;
    if (price) {
      let priceInFloat: number;

      if (typeof price === 'string') {
        priceInFloat = Price.currencyToFloat(price);
      } else {
        priceInFloat = price;
      }

      const priceInText = Price.decimalUSToBr(priceInFloat);
      setPriceText(priceInText);
    }
  }, [course?.price, live?.price, payment?.price]);

  useEffect(() => {
    if (isProfessional !== 'true') {
      if (courseParamId) {
        handleGetCourse();
      } else if (liveParamId) {
        handleGetLive();
      } else {
        handlePlan();
      }
      getPrice();
    }

    return cleanRequests;
  }, [courseParamId, getPrice, handleGetLive, handlePlan, liveParamId]);

  const handleCopyToClipboard = (): void => {
    if (couponName) {
      navigator.clipboard.writeText(couponName);
      addToast({
        title: 'Link copiado para área de transferência!',
        type: 'success',
      });
    }
  };

  return (
    // stripe - pagamento live
    <Container>
      <ButtonBack
        type="button"
        onClick={() => {
          goBack();
        }}
      >
        <FaArrowLeft color="#fff" size={20} />
      </ButtonBack>
      <img src={ImageLogo} alt="Logo" />
      <h1>Pagamento</h1>
      <h2>Preencha suas informações de pagamento.</h2>
      {((!courseParamId && !liveParamId) ||
        (courseParamId && isCourseSubscription)) && (
        <PaymentBox>
          {isProfessional === 'true' && (
            <PaymentInfo>
              <>
                <h1>Plano {state?.plan?.planName}</h1>
                <List>
                  <Item>
                    Pode postar até {state?.plan?.numberVideos} vídeos
                  </Item>
                  <Item>
                    Preço: R${' '}
                    {state?.plan?.planAmount?.toFixed(2)?.replace('.', ',')}
                  </Item>
                  <ButtonLink type="button" onClick={professionalSignsPlan}>
                    {' '}
                    Finalizar
                  </ButtonLink>
                </List>
              </>
            </PaymentInfo>
          )}
          {isPlan === 'true' && (
            <PaymentInfo>
              <>
                <h1>Detalhes do Plano</h1>
                <List>
                  <Item>
                    <img src={ImageCheckMark} alt="checkmark" />
                    Aulas gravadas do Básico ao Avançado + Aulas Bônus.
                  </Item>
                  <Item>
                    <img src={ImageCheckMark} alt="checkmark" />
                    Acesso à Plataforma Digital de Aulas ao Vivo.
                  </Item>
                  <Item>
                    <img src={ImageCheckMark} alt="checkmark" />
                    Acesso à Plataforma Digital de Módulos de Treinamento.
                  </Item>
                  <Item>
                    <img src={ImageCheckMark} alt="checkmark" />
                    Acesso à Plataforma Digital de Cursos.
                  </Item>
                  <Item>
                    <img src={ImageCheckMark} alt="checkmark" />
                    Suporte Técnico.
                  </Item>
                  <Item style={{ alignItems: 'baseline', width: '500px' }}>
                    <img src={ImageCheckMark} alt="checkmark" />
                    Este pagamento garante apenas a visualização das aulas
                    gravadas. Fica a critério do professor cobrar ou não por
                    cursos, módulos de treinamento e interações ao vivo.
                  </Item>
                  <DescountText>Digite seu cupom de desconto:</DescountText>
                </List>

                <DescountItem>
                  <input
                    value={couponName}
                    onChange={({
                      target: { value },
                    }: React.ChangeEvent<HTMLInputElement>) => {
                      setCouponName(value);
                    }}
                    placeholder="NomeDoCupom"
                  />
                </DescountItem>

                <LinkPayment
                  style={{ alignSelf: 'center' }}
                  type="button"
                  onClick={associatePlan}
                >
                  {' '}
                  Ir para pagamento
                </LinkPayment>
              </>
            </PaymentInfo>
          )}
        </PaymentBox>
      )}

      {/* {isPlan === 'true' && !couponName && (
        <PaymentInfo>
          <>
            <h1>Detalhes do Plano</h1>
            <List>
              <Item>
                <img src={ImageCheckMark} alt="checkmark" />
                Aulas gravadas do Básico ao Avançado + Aulas Bônus.
              </Item>
              <Item>
                <img src={ImageCheckMark} alt="checkmark" />
                Acesso à Plataforma Digital de Aulas ao Vivo.
              </Item>
              <Item>
                <img src={ImageCheckMark} alt="checkmark" />
                Acesso à Plataforma Digital de Módulos de Treinamento.
              </Item>
              <Item>
                <img src={ImageCheckMark} alt="checkmark" />
                Acesso à Plataforma Digital de Cursos.
              </Item>
              <Item>
                <img src={ImageCheckMark} alt="checkmark" />
                Suporte Técnico.
              </Item>
              <Item style={{ alignItems: 'baseline', width: '500px' }}>
                <img src={ImageCheckMark} alt="checkmark" />
                Este pagamento garante apenas a visualização das aulas gravadas.
                Fica a critério do professor cobrar ou não por cursos, módulos
                de treinamento e interações ao vivo.
              </Item>
            </List>
            <LinkPayment
              style={{ alignSelf: 'center' }}
              type="button"
              onClick={associatePlan}
            >
              {' '}
              Ir para pagamento
            </LinkPayment>
          </>
        </PaymentInfo>
      )} */}

      {courseParamId && !isCourseSubscription && (
        <PaymentBox style={{ maxWidth: 600 }}>
          <PaymentInfo>
            <h1>Detalhes do Curso</h1>
            <p>
              <strong>Nome:</strong>
              {course?.title}
            </p>
            <p>
              <strong>Professor(a):</strong>
              {course?.professional?.user?.name}
            </p>

            {priceText ? (
              <span>
                R$ <strong>{priceText}</strong>
              </span>
            ) : (
              <span>Gratuito</span>
            )}

            <DescountItem>
              <DescountTextTwo>Digite seu cupom de desconto:</DescountTextTwo>
              <input
                value={couponName}
                onChange={({
                  target: { value },
                }: React.ChangeEvent<HTMLInputElement>) => {
                  setCouponName(value);
                }}
              />
            </DescountItem>

            <ButtonLink type="button" onClick={associateCourse}>
              {' '}
              Ir para pagamento
            </ButtonLink>
            <Link
              to={{
                pathname: 'student/my-courses',
                state: { publicname },
              }}
            >
              <ButtonLink type="button">Ver seus módulos e cursos</ButtonLink>
            </Link>
          </PaymentInfo>
        </PaymentBox>
      )}
      {liveParamId && (
        <PaymentBox style={{ maxWidth: 600 }}>
          <PaymentInfo>
            <>
              <h1>Detalhes da Aula Ao Vivo</h1>
              <p>
                <strong>Nome:</strong>
                {live?.title}
              </p>
              <p>
                <strong>Professor(a):</strong>
                {publicname}
              </p>
            </>

            {live?.is_free ? (
              <span>Gratuito</span>
            ) : (
              <span>
                R$ <strong>{priceText}</strong>
              </span>
            )}

            {!live?.is_free && (
              <DescountItem>
                <DescountTextTwo>Digite seu cupom de desconto:</DescountTextTwo>
                <input
                  value={couponName}
                  onChange={({
                    target: { value },
                  }: React.ChangeEvent<HTMLInputElement>) => {
                    setCouponName(value);
                  }}
                />
              </DescountItem>
            )}

            <LinkPayment type="button" onClick={associateLive}>
              Ir para pagamento
            </LinkPayment>
            <Link
              to={{
                pathname: 'student/my-courses',
                state: { publicname },
              }}
            >
              <ButtonLink type="button">Ver seus módulos e cursos</ButtonLink>
            </Link>
          </PaymentInfo>
        </PaymentBox>
      )}
    </Container>
  );
};

export default Payment;
