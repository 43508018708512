// //
import { useCallback, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import {
  CategoriesMenu,
  Header,
  Lives,
  Paginate,
  ToggleMenu,
  Workouts,
} from '~/components';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import { PageOptionsRequest } from '~/models/Common';
import { Lives as ILives, LivesData } from '~/models/Lives';
import { Workouts as IWorkouts, WorkoutsData } from '~/models/Workouts';
import LivesService from '~/services/LivesService';
import ProfessorService from '~/services/ProfessorService';
import errorHandlerToToast from '~/utils/errorHandler';
import { useQuery } from '~/utils/query';

import * as M from './styles';

import ViewWorkout from './ViewWorkout';

export interface QueryProps {
  category_id: number;
  label: string;
  to: string;
  queryName: string;
}

const MyWorkouts: React.FC = () => {
  const queryParam = useQuery().get('level') as string | null;

  const { user, logout } = useAuth();

  const id = user.role === 'professional' ? user.professional.id : -1;
  const [levelId, setLevelId] = useState<number>(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownSelected, setDropdownSelected] = useState<string>();
  const [isNeedUpdate, setNeedUpdate] = useState(true);
  const [workouts, setWorkouts] = useState<WorkoutsData>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: 0,
  });
  /** @todo as lives estao sendo chamadas mesmo quando nao necessario */
  const [lives, setLives] = useState<LivesData>({
    data: [],
    lastPage: 0,
    page: 1,
    perPage: 1,
    total: 0,
  });

  const [menuVisible, setMenuVisible] = useState(false);
  const [selectedworkout, setSelectedWorkout] = useState<IWorkouts>();
  const [selectedLive, setSelectedLive] = useState<ILives>();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [categoriesMenuOptions, setCategoriesMenuOptions] = useState<
    QueryProps[]
  >([]);

  const { setLoading, setPecent } = useLoading();
  const [livesPageOptions, setLivesPageOptions] = useState<PageOptionsRequest>({
    page: 1,
    perpage: 4,
  });
  const [pageOptions, setPageOptions] = useState<PageOptionsRequest>({
    page: 1,
    perpage: 4,
  });
  const { addToast } = useToast();
  const { pathname } = useHistory().location;

  const handleGetcategoriesMenuOptions = async (): Promise<void> => {
    try {
      const response = await ProfessorService.getProfessionCategories();

      // ? New
      const professionalCategoriesIds: number[] = [];

      if (lives?.data?.length > 0) {
        const liveCategory = response?.data?.professionscategories?.filter(
          category => category.description === 'Ao vivo',
        );

        if (liveCategory.length > 0) {
          professionalCategoriesIds.push(liveCategory[0].id);
        }
      }

      if (workouts?.data?.length > 0) {
        const onlyCategoriesIds = workouts?.data
          ?.map(workout => workout.category?.id)
          .filter(categoryId => categoryId !== undefined);

        const filteredOnlyCategoriesIds = Array?.from(
          new Set(onlyCategoriesIds),
        );

        const workoutCategories = response?.data?.professionscategories?.filter(
          category => filteredOnlyCategoriesIds.includes(category.id),
        );

        professionalCategoriesIds.push(
          ...workoutCategories.map(category => category.id),
        );
      }
      setCategoriesMenuOptions(
        response?.data?.professionscategories
          ?.filter(item => professionalCategoriesIds.includes(item.id))
          ?.map(item => {
            return {
              label: item.description,
              queryName: item.description,
              to: `my-workouts?level=${item.description}`,
              category_id: item.id,
            };
          }),
      );

      // ? Old
      // setCategoriesMenuOptions(
      //   response.data.professionscategories?.map(item => {
      //     return {
      //       label: item.description,
      //       queryName: item.description,
      //       to: `my-workouts?level=${item.description}`,
      //       category_id: item.id,
      //     };
      //   }),
      // );
      const queryLevelId = queryParam ?? '';

      const trainings = response?.data?.professionscategories?.filter(
        item => item.description === queryLevelId,
      );

      // ? trainings[0].id é quem tá quebrando
      setLevelId(
        trainings?.length > 0
          ? trainings[0].id
          : response.data.professionscategories[0].id,
      );
    } catch (error: any) {
      console.log(error);
      const errorToToast = errorHandlerToToast({ error });
      if (errorToToast) addToast(errorToToast);
    }
  };

  const getWorkouts = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await ProfessorService.getWorkoutsByProfessional(
        id,
        pageOptions,
        levelId,
      );

      setWorkouts(data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      const errToToast = errorHandlerToToast({ error: err });
      if (errToToast) addToast(errToToast);
    }
  }, [addToast, id, levelId, pageOptions, setLoading]);

  const getLives = useCallback(async () => {
    setLoading(true);
    try {
      if (user.role === 'professional') {
        const { data } = await LivesService.getProfessionalLives(
          user.professional.public_name,
          levelId,
          livesPageOptions,
        );

        setLives(data);
      } else {
        throw new Error('User must be a professional.');
      }
    } catch (err: any) {
      const errToToast = errorHandlerToToast({ error: err });
      if (errToToast) addToast(errToToast);
    } finally {
      setLoading(false);
    }
  }, [addToast, setLoading, user, levelId, livesPageOptions]);

  const handleDeleteVideo = useCallback(
    async (idVideo: number) => {
      setLoading(true);
      try {
        if (user.role !== 'professional') {
          throw new Error('Você não é um professor');
        }
        const { id: idProfessional } = user.professional;
        await ProfessorService.deleteVideo({ idProfessional, idVideo });
        setLoading(false);
        addToast({
          title: 'Video excluido com suceso',
          type: 'success',
        });
        setNeedUpdate(true);
      } catch (error: any) {
        setLoading(false);
        const errorToToast = errorHandlerToToast({ error });
        if (errorToToast) addToast(errorToToast);
      }
    },
    [addToast, setLoading, user],
  );

  const handleOpenWorkoutModal = useCallback(
    workout => {
      if (!workout.is_converted) {
        addToast({
          title: 'Seu video está sendo processado!',
          type: 'info',
        });
        return;
      }

      setModalIsOpen(!modalIsOpen);
      setSelectedWorkout(workout);
    },
    [modalIsOpen],
  );

  const handleOpenLiveModal = useCallback(
    (live: ILives) => {
      setModalIsOpen(!modalIsOpen);
      setSelectedLive({
        ...live,
        date: `${live.date.split('T')[0].split('-')[2]}/${
          live.date.split('T')[0].split('-')[1]
        }`,
        hour: `${live.hour.split(':')[0]}:${live.hour.split(':')[1]}h `,
      });
    },
    [modalIsOpen],
  );

  const handleCloseModal = (): void => {
    setModalIsOpen(false);
    setSelectedWorkout(undefined);
    setSelectedLive(undefined);
  };

  const handleDeleteLive = useCallback(
    async (liveId: number) => {
      try {
        setLoading(true);

        if (user.role === 'professional') {
          await LivesService.deleteLive(
            { professionalId: user.professional.id, liveId },
            setPecent,
          );

          getLives();

          setLoading(false);
        } else {
          throw new Error('User must be a professional');
        }
      } catch (error: any) {
        setLoading(false);
        const errorToToast = errorHandlerToToast({ error });
        if (errorToToast) addToast(errorToToast);
      }
    },
    [addToast, getLives, setLoading, setPecent, user],
  );

  useEffect(() => {
    setPageOptions({
      page: 1,
      perpage: 4,
    });
    setLivesPageOptions({
      page: 1,
      perpage: 4,
    });
  }, [pathname]);

  useEffect(() => {
    if (isNeedUpdate) {
      getWorkouts();
      getLives();

      setNeedUpdate(false);
    }
  }, [getLives, getWorkouts, isNeedUpdate, levelId]);

  useEffect(() => {
    getWorkouts();
    getLives();
  }, [pageOptions.page, levelId]);

  useEffect(() => {
    handleGetcategoriesMenuOptions();
  }, [workouts, lives]);

  return (
    <>
      <M.MyWorkoutsContainer>
        <div id="showonmobile">
          <ToggleMenu
            title="Categorias"
            alignItems="center"
            setContentVisible={setMenuVisible}
            contentVisible={menuVisible}
          >
            <CategoriesMenu
              handleClose={setLevelId}
              options={categoriesMenuOptions}
              query={queryParam ?? ''}
            />
          </ToggleMenu>
        </div>
        <div id="hideonmobile">
          <CategoriesMenu
            handleClose={setLevelId}
            options={categoriesMenuOptions}
            query={queryParam ?? ''}
          />
        </div>
        <Header
          headerTitle="Ao Vivo"
          buttonText="Nova aula ao vivo"
          {...{ setNeedUpdate }}
        />
        <Lives
          isProfessional
          lives={lives.data}
          gridPadding="0 0 var(--gap-lg) 0"
          dropdownProps={{
            dropdownOpen,
            dropdownSelected,
            setDropdownOpen,
            setDropdownSelected,
            setNeedUpdate,
          }}
          handleDeleteLive={handleDeleteLive}
          handleOpenLiveModal={handleOpenLiveModal}
          getLives={getLives}
        />
        <footer style={{ margin: 'var(--gap-md) 0' }}>
          <Paginate
            pageCount={lives.lastPage}
            pageRangeDisplayed={lives.page}
            {...{ setPageOptions: setLivesPageOptions }}
          />
        </footer>
        <Header
          headerTitle="Aulas Gravadas"
          buttonText="Nova aula gravada"
          {...{ setNeedUpdate }}
        />
        <Workouts
          levelId={levelId}
          workouts={workouts?.data}
          getWorkouts={getWorkouts}
          dropdownProps={{
            dropdownOpen,
            setDropdownOpen,
            dropdownSelected,
            setDropdownSelected,
          }}
          handleDeleteWorkout={handleDeleteVideo}
          handleOpenWorkoutModal={handleOpenWorkoutModal}
          isProfessional
        />
        {workouts?.data?.length === 0 && lives?.data?.length === 0 && (
          <M.NoCategoryWorkoutTitle>
            Você ainda não possui nenhuma aula cadastrada desta categoria.
          </M.NoCategoryWorkoutTitle>
        )}
        <footer style={{ margin: 'var(--gap-md) 0' }}>
          <Paginate
            pageCount={workouts?.lastPage}
            pageRangeDisplayed={workouts?.page}
            {...{ setPageOptions }}
          />
        </footer>
      </M.MyWorkoutsContainer>
      <ViewWorkout
        modalIsOpen={modalIsOpen}
        setModalIsOpen={handleCloseModal}
        workout={selectedworkout}
        live={selectedLive}
      />
    </>
  );
};

export default MyWorkouts;
