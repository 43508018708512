import styled, { keyframes } from 'styled-components';

import { ImageBackground2 } from '~/assets/images';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Label = styled.label`
  width: 90%;
  color: #ffffff;
  font-size: 21px;
`;

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: url(${ImageBackground2}) 0% / cover;
    filter: grayscale(100%) blur(5px);
    z-index: -1;
  }
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: -1;
  }

  h1 {
    color: #ffffff;
    font-size: 32px;
    font-weight: 700;
    margin-top: 50px;
    text-transform: uppercase;
  }

  h6 {
    color: #ffffff;
    font-size: 20px;
    font-weight: 400;
    margin-top: 15px;
  }

  form {
    max-width: 840px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: ${appearFromRight} 1s;
  }
  img {
    max-width: 500px;
    width: 100%;
  }

  @media (max-width: 768.9px) {
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    h1 {
      text-align: center;
      font-size: 20px;
    }

    h6 {
      text-align: center;
      font-size: 15px;
    }
    form {
      max-width: 100%;
      flex-direction: column;

      div {
        margin-top: 30px;
        margin-left: 0px;
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: flex-start;
  align-items: flex-end;
  margin-top: 30px;
  width: 100%;

  > label {
    max-width: 385px;
    width: 100%;
    height: 385px;
    border: 2px solid #ffffff;
    border-radius: 4px;
    background: #242424c9 0% 0% no-repeat padding-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    overflow: hidden;

    > input {
      display: none;
    }

    img {
      width: 85px;
      height: 85px;
    }

    span {
      color: #ffffff;
      font-size: 21px;
      font-weight: 500;
      margin-top: 35px;
      text-align: center;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      margin-left: 15px;
      width: 90%;
      color: #ffffff;
      font-size: 21px;
    }

    input {
      margin-top: 10px;
      &::placeholder {
        color: #c2c2c2;
        font-weight: 400;
      }
    }
  }

  div + div {
    margin-top: 2rem;
  }

  @media (max-width: 768.9px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    align-items: center;
    flex-direction: column;

    div {
      width: 95%;
      justify-content: center;
      align-items: center;

      div {
        width: 100%;
        margin-left: 0px;
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  /* max-width: 620px; */
  width: 100%;
  margin-top: 25px;

  Link button,
  button {
    /* margin-right: 20px; */
    /* margin-top: 20px; */
    max-width: 420px;
    width: 100%;
    height: auto;
    padding: 14px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    padding: 0 15px 15px 15px;
    justify-content: center;
    align-items: center;
  }
`;
