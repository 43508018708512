import styled from 'styled-components';
import { colors } from '~/styles/settings/colors';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 15px;

  h1 {
    color: #2c2c2c;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 1.12px;
  }

  h2 {
    text-transform: uppercase;
    font-size: 28px;
    font-weight: 400;

    strong {
      color: #b88746;
    }
  }

  h6 {
    margin-top: 15px;
    color: #2c2c2c;
    font-size: 18px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      max-width: 373px;
      width: 100%;
      margin-top: 25px;
      border-color: #000;
      color: #fff;

      input {
        color: #2c2c2c;
        width: 100%;
        max-width: 373px;

        &::placeholder {
          color: #2c2c2c;
        }
      }
    }
  }
`;

export const Video = styled.video`
  height: 429px;
  max-width: 797px;
  width: 100%;

  @media (max-width: 929px) {
    margin-top: 30px;
  }

  @media (max-width: 768.9px) {
    max-height: 429px;
    height: 100%;
  }
`;
export const Content = styled.div`
  max-width: 797px;
  width: 100%;

  h6 {
    letter-spacing: 0.68px;
    color: ${colors.lightGrey};
    text-transform: uppercase;
    font-size: 17px;
    font-weight: 400;
  }

  h1 {
    letter-spacing: 1.36px;
    color: ${colors.lightGrey};
    font-size: 34px;
    margin-top: 5px;
  }
  p {
    color: ${colors.lightGrey};
    font-size: 20px;
    font-weight: 400;
    margin-top: 12px;
  }
`;
