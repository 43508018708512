import { useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import merge from 'lodash.merge';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { MixedSchema } from 'yup/lib/mixed';
import { SchemaLike } from 'yup/lib/types';

import { Header, Input, InputMask, Modal, Select } from '~/components';
import { ModalContent } from '~/components/Modal/styles';
import { useAuth } from '~/hooks/Auth';
import { useLoading } from '~/hooks/Loading';
import { useToast } from '~/hooks/Toast';
import {
  PageCustomizeFormData,
  RequiredForm,
  RequiredFormAmountNumber,
  SelectOptionProps,
  SubmitHandler,
} from '~/models/Common';
import { Professional, ViaCepAddress } from '~/models/User';
import UserSevice from '~/services/UserService';
import { convertPublicName } from '~/utils/convertPublicName';
import errorHandlerToToast from '~/utils/errorHandler';
import { formatDate, getMinorMaxDate } from '~/utils/getTodayDate';
import getValidationErrors from '~/utils/getValidationErrors';
import { getCEP } from '~/utils/handleCEP';
import { mockStates } from '~/utils/mocks/mockStates';
import Price from '~/utils/Price';
import { cast, removeMark } from '~/utils/yupHelpers';

import {
  Button,
  ChangePasswordButton,
  ConfigurationContainer,
  Data,
  DataGroupComplemento,
  Label,
  Subtext,
  Title,
} from './styles';

import ChangePassword from './ChangePassword';

interface ConfigurationProps {
  optionalHandlerSubmit?: (data: PageCustomizeFormData) => Promise<string>;
}

const Configuration: React.FC<ConfigurationProps> = ({
  children,
  optionalHandlerSubmit,
}) => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { setLoading } = useLoading();
  const { user, updateUserAndBack } = useAuth();
  const [duedate, setDuedate] = useState('');
  const [selectedAddressState, setSelectedAddressState] = useState(
    user.address_state || '',
  );
  const addressStateOptions = useRef<SelectOptionProps[]>(
    mockStates?.map(k => ({ label: k.sigla, value: k.sigla })),
  );
  const [lastCEP, setLastCEP] = useState<string>();
  const dueDateOptions = useRef<SelectOptionProps[]>(
    [10, 20]?.map(k => ({ label: k.toString(), value: k })),
  );
  const [initialData, setInitialData] = useState<RequiredFormAmountNumber>();
  const myId = useRef(
    user.role === 'student' ? user.student.id : user.professional.id,
  );
  const history = useHistory();

  const [address, setAddress] = useState<ViaCepAddress>({
    address_city: user.address_city ?? '',
    address_complement: user.address_complement ?? '',
    address_neighborhood: user.address_neighborhood ?? '',
    address_state: user.address_state ?? '',
    address_street: user.address_street ?? '',
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalDeleteAccountIsOpen, setModalDeleteAccountIsOpen] = useState(
    false,
  );

  const handleModalOpen = (): void => {
    setModalIsOpen(true);
  };

  // const handleUserWat = (): void => {
  //   const baseUrl = 'https://wa.me/';
  //   const { href } = new URL(`+5521994118964`, baseUrl);
  //   window.open(href,'_blank')
  // };

  const handlDeleteAccount = (): void => {
    setModalDeleteAccountIsOpen(preState => !preState);
  };

  const deleteAccount = (): void => {
    // try {
    //   await
    // } catch (error) {

    // }
    console.log('usuario deletado');
  };

  const propWhenUserIsProfessional = (schema: SchemaLike): MixedSchema => {
    const prop = Yup.mixed().when('$isProfessional', {
      is: true,
      then: schema,
    });
    return prop;
  };

  const handleSubmit: SubmitHandler<RequiredForm> = useCallback(
    async preData => {
      setLoading(true);
      const professional: Partial<Professional> = {};
      const otherFields: Partial<RequiredForm> = {};

      if (preData.professional) {
        const {
          amount_receivable,
          nick_name,
          original_price,
          cnpj, // Adicionei cnpj aqui
        } = preData.professional;

        if (original_price) {
          const originalPrice = Price.currencyToFloat(original_price);
          professional.original_price = originalPrice;
        }
        if (amount_receivable) {
          const amountReceivable = Price.currencyToFloat(amount_receivable);
          professional.amount_receivable = amountReceivable;
        }
        if (nick_name) {
          const convertedName = convertPublicName(nick_name);
          professional.public_name = convertedName;
        }

        if (cnpj) {
          professional.cnpj = removeMark(cnpj);
        }

        if (duedate) {
          professional.due_date = duedate;
        }
      }

      if (preData.cell_phone) {
        otherFields.cell_phone = removeMark(preData.cell_phone);
      }

      if (preData.cpf) {
        otherFields.cpf = removeMark(preData.cpf);
      }

      try {
        if (preData.date_of_birth) {
          // eslint-disable-next-line no-param-reassign
          preData.date_of_birth = formatDate(preData.date_of_birth);
          const isValid =
            new Date(preData.date_of_birth).toString() !== 'Invalid Date';
          if (!isValid) {
            formRef.current?.setFieldError('date_of_birth', 'Data inválida');
            throw new Error('Data inválida');
          }
        } else {
          formRef.current?.setFieldError('date_of_birth', 'Data é obrigatória');
          throw new Error('Data inválida');
        }

        formRef.current?.setErrors({});
        // Sempre mandar a UF em UpperCase
        if (preData) {
          // eslint-disable-next-line no-param-reassign
          preData.address_state = selectedAddressState;
        }

        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Nome obrigatório.')
            .matches(/^[A-zÀ-ú\s]+$/, 'Apenas caracteres alfabéticos!'),
          cpf: Yup.number()
            .integer()
            .positive()
            .validCPF('É preciso um CPF válido')
            .required('CPF obrigatório.')
            .transform(cast),
          email: Yup.string()
            .required('E-mail obrigatório.')
            .email('E-mail inválido.'),
          cell_phone: Yup.string().min(11, 'Insira um telefone válido'),
          date_of_birth: Yup.date()
            .min(getMinorMaxDate(120, 'min'), 'Idade inválida')
            .max(getMinorMaxDate(18, 'min'), 'Mínimo 18 anos')
            .nullable()
            .required('Data de aniversário obrigatória'),
          address_zip_code: Yup.number()
            .integer()
            .positive()
            .validCEP('É preciso um CEP válido')
            .transform(cast),
          address_street: Yup.string(),
          address_number: Yup.number().integer().positive().transform(cast),
          address_complement: Yup.string(),
          address_neighborhood: Yup.string(),
          address_state: Yup.string(),
          address_city: Yup.string(),
          professional: propWhenUserIsProfessional(
            Yup.object().shape({
              cref: Yup.string().required(),
              due_date: Yup.number().max(31).min(1).transform(cast),
              pix_key: Yup.string(),
              original_price: Yup.number().transform(cast),
              amount_receivable: Yup.number()
                .min(19.9, 'Valor mínimo de R$ 19,90')
                .max(999, 'Valor máximo de R$ 999,00')
                .transform(cast),
              nick_name: Yup.string().required('Nome público obrigatório'),
              cnpj: Yup.string().required('CNPJ obrigatório'), // Adicionei a validação do CNPJ aqui
            }),
          ),
        });

        const data = merge(preData, otherFields, {
          professional,
        }) as RequiredFormAmountNumber;

        await schema.validate(data, {
          abortEarly: false,
          context: { isProfessional: user.role === 'professional' },
        });

        const { data: userUpdated } = await UserSevice.update({
          data,
          userId: myId.current,
          type: user.role,
        });
        console.log(data);

        setLoading(false);

        addToast({ title: 'Dados alterados com sucesso', type: 'success' });

        let avatar: string | undefined;

        if (
          user.role === 'professional' &&
          data?.professional?.public_name &&
          data?.professional?.nick_name &&
          optionalHandlerSubmit
        ) {
          setLoading(true);
          avatar = await optionalHandlerSubmit({
            public_name: data.professional.public_name,
            nick_name: data.professional.nick_name,
          });
          setLoading(false);
        }

        if (avatar) {
          userUpdated.avatar = avatar;
        }

        updateUserAndBack({
          user: userUpdated,
          redirect: false,
        });

        history.goBack();
      } catch (error: any) {
        setLoading(false);
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) {
          addToast(responseErr);
        }
        const errors = getValidationErrors(error);

        Object.values(errors).forEach((value: string) => {
          addToast({
            title: 'Verifique os dados inseridos',
            description: value,
            type: 'error',
          });
        });
      }
    },
    [
      addToast,
      duedate,
      optionalHandlerSubmit,
      setLoading,
      updateUserAndBack,
      user.role,
      history,
      selectedAddressState,
    ],
  );

  const handleCEP = useCallback(
    async ({
      target: { value: cepWithMark },
    }: React.ChangeEvent<HTMLInputElement>) => {
      try {
        const addressViaCep = await getCEP(cepWithMark, lastCEP);
        setLastCEP(cepWithMark);
        setAddress(addressViaCep);
      } catch (error: any) {
        const errorToToast = errorHandlerToToast({ error });
        if (errorToToast) addToast(errorToToast);
      }
    },
    [addToast, lastCEP],
  );

  useEffect(() => {
    if (user.role === 'professional' && user.professional.due_date) {
      setDuedate(user.professional.due_date);
    }

    console.log(user);

    setInitialData({
      name: user.name,
      cpf: user.cpf,
      cnpj: user.cnpj,
      email: user.email,
      cell_phone: user.cell_phone,
      date_of_birth: user.date_of_birth
        ? formatDate(user.date_of_birth.split(' ')[0], 'invert')
        : '',
      address_zip_code: user.address_zip_code,
      address_number: user.address_number,
      professional:
        user.role === 'professional' ? user.professional : undefined,
      ...address,
    });
  }, [address, user]);

  return (
    <>
      <Header headerTitle="Configurações" />
      <ConfigurationContainer>
        <Title>Dados</Title>
        <Form ref={formRef} onSubmit={handleSubmit} {...{ initialData }}>
          <Data>
            <Label>
              Nome:
              <Input name="name" />
            </Label>
            {user.role === 'professional' && (
              <Label>
                CREF:
                <Input name="professional.cref" />
              </Label>
            )}
            <Label>
              CPF:
              <InputMask mask="999.999.999-99" name="cpf" />
            </Label>
            <Label>
              E-mail:
              <Input name="email" type="email" />
            </Label>
            <Label>
              Telefone:
              <InputMask name="cell_phone" type="tel" mask="(99) 99999-9999" />
            </Label>
            <Label>
              Data de nascimento:
              <InputMask
                name="date_of_birth"
                type="tel"
                placeholder="dd/mm/aaaa"
                mask="99/99/9999"
              />
            </Label>
            <Label>
              CEP:
              <InputMask
                name="address_zip_code"
                mask="99999-999"
                onBlur={handleCEP}
              />
            </Label>
            <Label>
              Endereço:
              <Input name="address_street" />
            </Label>
            <DataGroupComplemento>
              <Label>
                Nº:
                <Input name="address_number" />
              </Label>
              <Label>
                Complemento:
                <Input name="address_complement" />
              </Label>
            </DataGroupComplemento>
            <Label>
              Bairro:
              <Input name="address_neighborhood" />
            </Label>
            <Label>
              Estado:
              {/* <Input name="address_state" /> */}
              <Select
                name="address_state"
                options={addressStateOptions.current}
                value={selectedAddressState}
                setValue={setSelectedAddressState}
              />
            </Label>
            <Label>
              Cidade:
              <Input name="address_city" />
            </Label>
            {user.role === 'professional' && (
              <>
                {/* <Label>
                  Dia de recebimento
                  <Select
                    name="professional.due_not_date"
                    options={dueDateOptions.current}
                    value={duedate}
                    setValue={setDuedate}
                  />
                </Label> */}
                {/* <Label>
                  Chave Pix:
                  <Input name="professional.pix_key" />
                </Label> */}
                <Label>
                  CNPJ:
                  <InputMask
                    mask=" 99. 999. 999/9999-99"
                    name="professional.cnpj"
                  />
                </Label>
              </>
            )}
          </Data>
          {user.role === 'student' && (
            <Subtext>Cancelamento da assinatura em até 7 dias.</Subtext>
          )}
          <Data style={{ paddingLeft: '0' }}>
            <ChangePasswordButton
              type="button"
              isOutline
              onClick={handleModalOpen}
            >
              Alterar senha
            </ChangePasswordButton>
            <ChangePasswordButton
              type="button"
              isOutline
              onClick={handlDeleteAccount}
            >
              Excluir conta
            </ChangePasswordButton>
          </Data>
          {children}
          <ChangePasswordButton type="submit" isOutline>
            Salvar alterações
          </ChangePasswordButton>
        </Form>
      </ConfigurationContainer>
      <ChangePassword
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
      <Modal
        modalIsOpen={modalDeleteAccountIsOpen}
        setModalIsOpen={setModalDeleteAccountIsOpen}
      >
        <ModalContent>
          <h2>Tem certeza que deseja deletar a sua conta ?</h2>
          <ChangePasswordButton type="button" isOutline onClick={deleteAccount}>
            Excluir conta
          </ChangePasswordButton>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Configuration;
