import { AxiosPromise } from 'axios';

import { PageOptionsRequest, PageOptionsResponse } from '../models/Common';
import { Workouts } from '../models/Workouts';
import api, { getToken } from './api';

const PER_PAGE_DEFAULT = process.env.REACT_APP_PER_PAGE_DEFAULT ?? 12;

export default {
  getStudentLives(
    studentId: number,
    categoryId: number | string,
    { page, perpage = PER_PAGE_DEFAULT }: PageOptionsRequest = {
      page: 1,
      perpage: PER_PAGE_DEFAULT,
    },
  ): AxiosPromise<PageOptionsResponse<Workouts>> {
    return api.get(`students/${studentId}/lives`, {
      params: {
        page,
        perpage,
        category: categoryId,
      },
      cancelToken: getToken().token,
    });
  },

  getStudentWorkouts({
    studentId,
    categoryId,
    page = 1,
    perpage = PER_PAGE_DEFAULT,
  }: {
    studentId: number;
    categoryId?: number | string;
    page?: number;
    perpage?: number;
  }): AxiosPromise<PageOptionsResponse<Workouts>> {
    return api.get(`students/${studentId}/professionals`, {
      params: {
        page,
        perpage,
        category: categoryId,
      },
      cancelToken: getToken().token,
    });
  },

  getStudentWorkoutsByProfessional({
    studentId,
    categoryId,
    page = 1,
    perpage = PER_PAGE_DEFAULT,
    professionalId,
  }: {
    studentId: number;
    categoryId?: number | string;
    page?: number;
    perpage?: number;
    professionalId: number;
  }): AxiosPromise<PageOptionsResponse<Workouts>> {
    return api.get(`students/${studentId}/professional/${professionalId}`, {
      params: {
        page,
        perpage,
      },
      cancelToken: getToken().token,
    });
  },

  getWorkoutsCount(
    studentId: number,
  ): AxiosPromise<{ treining_category_id: number }[]> {
    return api.get(`students/${studentId}/count-professionals`, {
      cancelToken: getToken().token,
    });
  },

  getWorkoutsBanners(professionalId: string | number): AxiosPromise<any> {
    return api.get(`banner`, { params: { professionalId } });
  },
};
